<template>
  <DualRingLoader :loading="loading">
    <div class="card">
      <div class="card-body">
        <div class="text-center">
          <h4 class="text-info">{{ dt.Currency.name }} Wallet</h4>
        </div>

        <div class="text-center text-success">
          <h4 class="">Balance</h4>
          <h5 class="fw-bolder">{{ dt.Currency.code }} {{ dt.balance }}</h5>
        </div>
      </div>
      <div class="card-footer text-center">
        <button
          class="btn btn-sm btn-primary"
          data-bs-target="#credit-debit-wallet"
          data-bs-toggle="modal"
        >
          Credit/Debit Wallet
        </button>
      </div>

      <DefaultModal id="credit-debit-wallet">
        <DualRingLoader :loading="loading">
          <div class="card border-light p-4">
            <div class="card-header border-0 text-center pb-0">
              <h2 class="h4">
                Enter the amount you want debit/credit for
                {{ dt.Currency.code }} wallet belonging to user #{{
                  dt.user_id
                }}
              </h2>
            </div>
            <div class="card-body">
              <form
                @submit.prevent="
                  RequestConfirmation(
                    'Are you sure to proceed? This action is irreversible',
                    Submit
                  )
                "
                class="mt-4"
              >
                <div class="alert text-white alert-dark">
                  Please use a negative value to debit wallet
                </div>

                <!-- Form -->
                <div class="form-group mb-4">
                  <InputWrapper :errors="formError.amount">
                    <span class="input-group-text">
                      {{ dt.Currency.code }}
                    </span>
                    <input
                      type="number"
                      class="form-control form-control-lg px-1"
                      required
                      v-model.number="formData.amount"
                      placeholder="Amount"
                    />
                  </InputWrapper>
                </div>

                <div class="text-center">
                  <button type="submit" class="btn btn-block btn-dark">
                    Proceed
                  </button>
                </div>
              </form>
              <div
                class="d-flex justify-content-center align-items-center mt-4"
              >
                <span class="font-weight-normal">
                  <a
                    ref="closeButton"
                    class="font-weight-bold"
                    data-bs-dismiss="modal"
                    >Cancel</a
                  >
                </span>
              </div>
            </div>
          </div>
        </DualRingLoader>
      </DefaultModal>
    </div>
  </DualRingLoader>
</template>

<script lang="ts">
import { ref, defineComponent, Ref } from "vue";
import { apiPost } from "@/api";
import { RequestConfirmation, ShowSuccess, Toast } from "@/utils/notify";
import DefaultModal from "@/components/modal/DefaultModal.vue";
import InputWrapper from "@/components/InputWrapper.vue";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";

export default defineComponent({
  name: "EachUserWallet",
  components: { DualRingLoader, InputWrapper, DefaultModal },
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  emits: ["refresh"],
  setup(props, ctx) {
    const formData = ref({
      amount: null,
    });
    const formError = ref({});

    const loading = ref(false);

    const closeButton: Ref<HTMLAnchorElement | undefined> = ref(undefined);

    const Submit = async () => {
      loading.value = true;
      try {
        await apiPost(`/wallets/${props.dt.id}/credit`, formData.value);
        ShowSuccess();
        formData.value = {
          amount: null,
        };
        closeButton.value?.click();
        ctx.emit("refresh");
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle credit/debit wallet: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      RequestConfirmation,
      Submit,
      formData,
      formError,
      closeButton,
    };
  },
});
</script>

<style scoped></style>
