<template>
  <HourglassLoader :loading="loading">
    <section>
      <!-- <div class="d-flex justify-content-between align-items-center py-3">
          <div class="d-block">
            <h2 class="h4">Card Transactions</h2>
            <p class="mb-0">Below are the users withdrawals (cards)</p>
          </div>
  
          <div class="btn-toolbar">
            <button type="button" class="btn btn-sm btn-outline-primary" @click="RefreshData">Reload</button>
          </div>
      </div>-->

      <div class="table-settings mb-4">
        <div class="row align-items-center justify-content-end">
          <!-- <form class="col col-md-6 col-lg-3 col-xl-4" @submit.prevent="RefreshData">
              <div class="input-group">
                <span class="input-group-text">
                  <span class="fas fa-search"></span>
                </span>
                <input
                  type="text"
                  v-model="search"
                  class="form-control px-1"
                  placeholder="Find Transaction"
                  aria-label="Find Transaction"
                />
              </div>
          </form>-->
          <div class="col-4 col-md-2 col-xl-1 pl-md-0 d-flex text-right">
            <CountSelector
              v-if="data"
              v-model="data.meta.per_page"
              @updated="RefreshData"
              class="mr-2"
            />
            <DataFilterSelector v-model="status" :filters="filters" @updated="RefreshData" />
          </div>
        </div>
      </div>

      <div
        v-if="data?.transactions.length == 0"
        class="d-flex justify-content-center align-items-center py-3 text-muted"
      >No Transaction</div>
      <div class="card card-body border-light shadow-sm table-wrapper table-responsive pt-0">
        <table class="table table-hover min-h-400">
          <thead>
            <tr>
              <th>#</th>
              <th>Amount</th>
              <th>Fee</th>
              <th>Remark</th>
              <th>Comment</th>
              <th>Wallet address</th>
              <th>Coin name</th>
              <th>Network</th>
              <!-- <th>Currency</th> -->
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody v-if="data">
            <CoinWithdrawalRow v-for="(dt, index) in data.transactions" :key="index" :dt="dt" />
          </tbody>
        </table>
        <Pagination
          v-if="data"
          v-model="data.meta.page"
          :currentPage="data.meta.page"
          :totalRecord="data.meta.count"
          :dataCount="data.meta.per_page"
          @updated="RefreshData"
        />
      </div>
    </section>
  </HourglassLoader>
</template>
      
      <script lang="ts">
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import Pagination from "@/components/mini/Pagination.vue";
import CountSelector from "@/components/mini/CountSelector.vue";
import { onBeforeMount, ref, defineComponent } from "vue";
import { apiGet } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
// import TransactionRow from "@/views/withdrawals/constituent/TransactionRow.vue";
import CoinWithdrawalRow from "@/views/withdrawals/constituent/CoinWithdrawalRow.vue";

import DataFilterSelector from "@/components/selector/DataFilterSelector.vue";

export default defineComponent({
  name: "UserCryptoWithdrawals",
  components: {
    DataFilterSelector,
    // TransactionRow,
    HourglassLoader,
    Pagination,
    CountSelector,
    CoinWithdrawalRow,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false);
    const data = ref(null);
    const filters = {
      ALL: "All Withdrawals",
      SUBMITTED: "Submitted/New",
      CANCELED: "Canceled",
      PROCESSING: "Processing",
      DONE: "Done/Success",
      FAILED: "Done/Failed",
    };
    const status = ref(undefined);
    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `coin/withdraw/${props.user.id}`,
          Object.assign(
            ((data.value as unknown) as Record<string, unknown>)?.meta ?? {},
            {
              status: status.value === "ALL" ? undefined : status.value,
            }
          )
        );
        data.value = response.data.data;
        Toast.fire({
          icon: "success",
          title: "Crypto withdrawals Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      route,
      data,
      loading,
      filters,
      status,
    };
  },
});
</script>
      
      <style scoped></style>
      