<template>
  <DualRingLoader :loading="loading">
    <div v-if="data" class="row justify-content-around gy-4">
      <div class="text-center">
        <h4 class>KYC Level</h4>
        <h5 class="fw-bolder text-info">Level {{ data?.kyc_setting_id }}</h5>
      </div>
      <div class="text-center col-12 col-md-6 col-lg-4">
        <h4 class>Email</h4>
        <h5 class="fw-bolder text-info">{{ dt.email }}</h5>
      </div>

      <div class="text-center col-12 col-md-6 col-lg-4">
        <h4 class>BVN</h4>
        <div>
          <h5 v-if="!data?.bvn" class="fw-bolder text-info">
            <span>None</span>
          </h5>
          <div v-else>{{ data?.bvn }}</div>
        </div>
      </div>
      <div class="text-center col-12 col-md-6 col-lg-4">
        <h4 class>BVN Details</h4>
        <div>
          <h5 v-if="!data?.bvn_details" class="fw-bolder text-info">
            <span>None</span>
          </h5>
          <div v-else>
            <div class="my-1">
              <h6 class="fw-bolder text-info">First Name</h6>
              <div>{{ data?.bvn_details.firstName }}</div>
            </div>
            <div class="my-1">
              <h6 class="fw-bolder text-info">Last Name</h6>
              <div>{{ data?.bvn_details.lastName }}</div>
            </div>
            <div class="my-1">
              <h6 class="fw-bolder text-info">Date of Birth</h6>
              <div>{{ data?.bvn_details.dob }}</div>
            </div>
            <div class="my-1">
              <h6 class="fw-bolder text-info">Country Code</h6>
              <div>{{ data?.bvn_details.countryCode }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center col-12 col-md-6 col-lg-4">
        <h4 class>ADDRESS</h4>
        <div>
          <h5 v-if="!data?.address" class="fw-bolder text-info">
            <span>No Address</span>
          </h5>
          <div v-else>{{ data?.address }}</div>
        </div>
      </div>
      <div class="text-center col-12 col-md-6">
        <h4 class>ID Number</h4>
        <div>
          <h5 v-if="!data?.id_number" class="fw-bolder text-info">
            <span>No ID number</span>
          </h5>
          <div v-else>{{ data?.id_number }}</div>
        </div>
      </div>
      <div class="text-center col-12 col-md-6">
        <h4 class>ID type</h4>
        <div>
          <h5 v-if="!data?.id_type" class="fw-bolder text-info">
            <span>No type</span>
          </h5>
          <div v-else>{{ data?.id_type }}</div>
        </div>
      </div>
      <div class="text-center">
        <h4 class>ID</h4>
        <div>
          <h5 v-if="!data?.id_image_urls" class="fw-bolder text-info">
            <span>No ID Images</span>
          </h5>
          <div v-else class="row">
            <div class="col-12">
              <div class="card card-body border-light shadow-sm">
                <div class="row">
                  <img
                    class="col-12 col-md-6 g-2 g-lg-3"
                    v-for="(image, index) in data?.id_image_urls"
                    :src="image"
                    :key="index"
                    alt="Img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="text-center">
        <h4 class>KYC Settings ID</h4>
        <div>{{ data.kyc_setting_id }}</div>
      </div>-->
    </div>
  </DualRingLoader>
</template>
    
    <script lang="ts">
import { ref, defineComponent, onBeforeMount } from "vue";
import { apiGet } from "@/api";
import { Toast } from "@/utils/notify";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";

export default defineComponent({
  name: "UserKyc",
  components: { DualRingLoader },
  props: {
    dt: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  // emits: ["bank-details"],
  setup(props) {
    const loading = ref(false);
    const data = ref(null);

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/kyc/user/${props.user.id}`);
        data.value = response.data.data.userkyc;

        console.log(data.value);

        // ctx.emit("bank-details", data.value);

        Toast.fire({
          icon: "success",
          title: "User kyc Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      loading,
      data,
      RefreshData,
    };
  },
});
</script>
    
    <style scoped></style>
    