<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">User Details</h2>
        </div>
        <div class="btn-toolbar">
          <button type="button" class="btn btn-sm btn-outline-primary" @click="RefreshData">Reload</button>
        </div>
      </div>
      <div v-if="data" class="row">
        <div class="col-12 mt-5 mt-md-0">
          <div class="card card-body border-light shadow-sm">
            <button
              @click="showMobileMenu = !showMobileMenu"
              v-if="screenWidth < 768"
              class="btn btn-outline-dark d-lg-none d-block mb-3"
            >
              <span class="navbar-toggler-icon"></span>
              <span>{{ showMobileMenu ? 'Hide Menu': 'Show Menu' }} / {{ currentTab }}</span>
            </button>
            <div>
              <div
                v-if="screenWidth < 768 && showMobileMenu"
                class="btn-group-mobile"
                role="group"
                aria-label="Basic radio toggle button group"
                :class="screenWidth < 768 && showMobileMenu ? 'd-flex flex-column' : ''"
              >
                <button
                  @click="selectTab('profile')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'profile' ? 'active' : ''"
                >User Profile</button>
                <button
                  @click="selectTab('kyc')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'kyc' ? 'active' : ''"
                >User Kyc</button>

                <button
                  @click="selectTab('wallets')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'wallets' ? 'active' : ''"
                >Wallets</button>

                <button
                  @click="selectTab('banks')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'banks' ? 'active' : ''"
                >Bank Accounts</button>
                <button
                  @click="selectTab('crypto_transactions')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'crypto_transactions' ? 'active' : ''"
                  for="bankAccountsBtn"
                >Crypto Transactions</button>
                <button
                  @click="selectTab('card_transactions')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'card_transactions' ? 'active' : ''"
                >Card Transactions</button>
                <button
                  @click="selectTab('card_withdrawals')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'card_withdrawals' ? 'active' : ''"
                >Card Withdrawals</button>
                <button
                  @click="selectTab('crypto_withdrawals')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'crypto_withdrawals' ? 'active' : ''"
                >Crypto Withdrawals</button>
              </div>
              <div
                v-else
                class="btn-group"
                role="group"
                aria-label="Basic radio toggle button group"
                :class="screenWidth < 768 && !showMobileMenu ? 'd-none' : ''"
              >
                <button
                  @click="selectTab('profile')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'profile' ? 'active' : ''"
                >User Profile</button>
                <button
                  @click="selectTab('kyc')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'kyc' ? 'active' : ''"
                >User Kyc</button>

                <button
                  @click="selectTab('wallets')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'wallets' ? 'active' : ''"
                >Wallets</button>

                <button
                  @click="selectTab('banks')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'banks' ? 'active' : ''"
                >Bank Accounts</button>
                <button
                  @click="selectTab('crypto_transactions')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'crypto_transactions' ? 'active' : ''"
                >Crypto Transactions</button>
                <button
                  @click="selectTab('card_transactions')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'card_transactions' ? 'active' : ''"
                >Card Transactions</button>
                <button
                  @click="selectTab('card_withdrawals')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'card_withdrawals' ? 'active' : ''"
                >Card Withdrawals</button>
                <button
                  @click="selectTab('crypto_withdrawals')"
                  class="btn btn-outline-dark"
                  :class="currentTab == 'crypto_withdrawals' ? 'active' : ''"
                >Crypto Withdrawals</button>
              </div>
            </div>

            <div v-if="currentTab == 'profile'" class="user-profile">
              <div class="text-center">
                <h1 class="text-gray my-3">User Profile</h1>
              </div>

              <div class="col-12 pb-5">
                <div>
                  <h4 class="text-gray text-right">Total Balance</h4>
                  <div class="text-right mx-2" v-if="userBalance?.length > 0">
                    <div class="d-lg-flex flex-row justify-content-end align-items-center py-1">
                      <h5
                        class="fw-bolder text-info border border-r ms-3 p-3"
                        v-for="bal in userBalance"
                        :key="bal"
                      >
                        <span class="mr-3">{{bal.Currency.code}}</span>
                        {{ bal.balance }}
                      </h5>
                    </div>
                  </div>
                  <div v-else>
                    <p class="text-danger text-right">No Wallets Found</p>
                  </div>
                </div>
                <div class="card card-body border-light shadow-sm">
                  <div class="text-center">
                    <h4 class="text-gray">Email</h4>
                    <h5 class="fw-bolder">{{ data.email }}</h5>
                  </div>

                  <div class="row justify-content-center text-center py-3">
                    <div class="col-6">
                      <h4 class="text-gray">Clearance</h4>
                      <h6>{{ data.clearance }}</h6>
                    </div>
                    <div class="col-6">
                      <h4 class="text-gray">Username</h4>
                      <h6>{{ data.username }}</h6>
                    </div>
                  </div>

                  <div class="row text-center py-3">
                    <div class="col-12 col-md-4">
                      <h4 class="text-gray">UserId</h4>
                      <h6>{{ data.id }}</h6>
                    </div>
                    <div class="col-12 col-md-4">
                      <h4 class="text-gray">Name</h4>
                      <h6>
                        {{ data.Profile.first_name }}
                        {{ data.Profile.last_name }}
                      </h6>
                    </div>
                    <div class="col-12 col-md-4">
                      <h4 class="text-gray">Referral Code</h4>
                      <h6>{{ data.referral_code }}</h6>
                    </div>
                  </div>

                  <div class="row text-center py-3">
                    <div class="col-12 col-md-6">
                      <h4 class="text-gray">Registered At</h4>
                      <h6>{{ new Date(data.created_at).toLocaleString("en-US") }}</h6>
                    </div>
                    <div class="col-12 col-md-6">
                      <h4 class="text-gray">Last Updated At</h4>
                      <h6>{{ new Date(data.updated_at).toLocaleString("en-US") }}</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 pb-5">
                <div class="card card-body border-light shadow-sm">
                  <div class="text-center">
                    <h6 class="text-gray">Extra Information</h6>
                  </div>

                  <div class="row text-center py-3">
                    <div class="col-4">
                      <h4 class="text-gray">Phone Number</h4>
                      <h6>{{ data.Profile.phone }}</h6>
                    </div>
                    <div class="col-4">
                      <h4 class="text-gray">Boarded</h4>
                      <h6>{{ data.boarded ? "Yes" : "No" }}</h6>
                    </div>
                    <div class="col-4">
                      <h4 class="text-gray">Verified</h4>
                      <h6>{{ data.is_verified ? "Yes" : "No" }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="currentTab == 'wallets'" class="fiat-wallet">
              <div class="text-center">
                <h1 class="text-gray my-3">User Wallets</h1>
              </div>

              <UserWallets :dt="data" />
            </div>
            <div v-if="currentTab == 'banks'" class="user-bank-accounts mt-3">
              <div class="text-center">
                <h1 class="text-gray my-3">User Bank Accounts</h1>
              </div>
              <div>
                <UserBankAccounts :dt="data" />
              </div>
            </div>
            <div v-if="currentTab == 'crypto_transactions'" class="user-crypto-transactions mt-3">
              <div class="text-center">
                <h1 class="text-gray my-3">User Crypto Transactions</h1>
              </div>
              <div>
                <UserSellCryptoTransactions :user="data" />
              </div>
            </div>
            <div v-if="currentTab == 'card_transactions'" class="user-card-transactions mt-3">
              <div class="text-center">
                <h1 class="text-gray my-3">User Card Transactions</h1>
              </div>
              <div>
                <UserSellCardTransactions :user="data" />
              </div>
            </div>
            <div v-if="currentTab == 'card_withdrawals'" class="user-card-withdrawals mt-3">
              <div class="text-center">
                <h1 class="text-gray my-3">User Card Withdrawals</h1>
              </div>
              <div>
                <UserCardWithdrawals :user="data" />
              </div>
            </div>
            <div v-if="currentTab == 'crypto_withdrawals'" class="user-crypto-withdrawals mt-3">
              <div class="text-center">
                <h1 class="text-gray my-3">User Crypto Withdrawals</h1>
              </div>
              <div>
                <UserCryptoWithdrawals :user="data" />
              </div>
            </div>
            <div v-if="currentTab == 'kyc'" class="user-kyc mt-3">
              <div class="text-center">
                <h1 class="text-gray my-3">Kyc</h1>
              </div>
              <div>
                <UserKyc :dt="data" :user="data" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12">
          <h1 class="text-danger text-center">No Data!</h1>
        </div>
      </div>
    </section>
  </HourglassLoader>
</template>

<script lang="ts">
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import {
  onBeforeMount,
  ref,
  defineComponent,
  inject,
  watch,
  onMounted,
} from "vue";
import { apiGet, apiPost } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import Swal from "sweetalert2";
import UserWallets from "@/views/users/constituent/UserWallets.vue";
import UserBankAccounts from "@/views/users/constituent/UserBankAccounts.vue";
import UserSellCryptoTransactions from "./constituent/UserSellCryptoTransactions.vue";
import UserSellCardTransactions from "./constituent/UserSellCardTransactions.vue";
import UserCardWithdrawals from "./constituent/UserCardWithdrawals.vue";
import UserCryptoWithdrawals from "./constituent/UserCryptoWithdrawals.vue";
import UserKyc from "./constituent/UserKyc.vue";

export default defineComponent({
  name: "UserDetail",
  components: {
    UserWallets,
    UserBankAccounts,
    HourglassLoader,
    UserSellCryptoTransactions,
    UserSellCardTransactions,
    UserCardWithdrawals,
    UserCryptoWithdrawals,
    UserKyc,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);

    const screenWidth = ref(window.innerWidth);
    const showMobileMenu = ref(false);

    const userBalance = ref(null);

    const currentTab = ref("profile");

    const selectTab = (tab: string) => {
      currentTab.value = tab;
    };

    const RequestConfirmation = inject("RequestConfirmation");

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/user-account/${route.params.userId}`);
        data.value = response.data.data.user;
        getUserBalance();
        Toast.fire({
          icon: "success",
          title: "User Detail Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const getUserBalance = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/wallets/${route.params.userId}`);
        userBalance.value = response.data.data.fiatWallets;
        // Toast.fire({
        //   icon: "success",
        //   title: "User Detail Fetched Successfully",
        // }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    watch(screenWidth, () => {
      console.log("Screen width changed!");
    });

    onMounted(() => {
      window.addEventListener("resize", () => {
        screenWidth.value = window.innerWidth;
      });
    });

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      getUserBalance,
      RequestConfirmation,
      selectTab,
      screenWidth,
      showMobileMenu,
      currentTab,
      userBalance,
      route,
      data,
      loading,
    };
  },
});
</script>



