
import { ref, defineComponent, Ref } from "vue";
import { apiPost } from "@/api";
import { RequestConfirmation, ShowSuccess, Toast } from "@/utils/notify";
import DefaultModal from "@/components/modal/DefaultModal.vue";
import InputWrapper from "@/components/InputWrapper.vue";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";

export default defineComponent({
  name: "EachUserWallet",
  components: { DualRingLoader, InputWrapper, DefaultModal },
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  emits: ["refresh"],
  setup(props, ctx) {
    const formData = ref({
      amount: null,
    });
    const formError = ref({});

    const loading = ref(false);

    const closeButton: Ref<HTMLAnchorElement | undefined> = ref(undefined);

    const Submit = async () => {
      loading.value = true;
      try {
        await apiPost(`/wallets/${props.dt.id}/credit`, formData.value);
        ShowSuccess();
        formData.value = {
          amount: null,
        };
        closeButton.value?.click();
        ctx.emit("refresh");
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle credit/debit wallet: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      RequestConfirmation,
      Submit,
      formData,
      formError,
      closeButton,
    };
  },
});
