<template>
  <DualRingLoader :loading="loading">
    <div
      v-if="data?.length == 0"
      class="d-flex justify-content-center align-items-center py-3 text-muted"
    >No Transaction</div>
    <div class="row justify-content-around gy-4">
      <div v-for="(dt, index) in data" :key="index" class="col-12 col-lg-5 card p-2">
        <div class="card-body">
          <div class="text-center">
            <h4 class>Bank Name</h4>
            <h5 class="fw-bolder text-info">{{ dt.details.bank_name }}</h5>
          </div>
          <div class="text-center">
            <h4 class>Account Name</h4>
            <h5 class="fw-bolder text-info">{{ dt.details.account_name }}</h5>
          </div>
          <div class="text-center">
            <h4 class>Account Number</h4>
            <h5 class="fw-bolder text-info">{{ dt.account_number }}</h5>
          </div>
        </div>
      </div>
    </div>
  </DualRingLoader>
</template>
  
  <script lang="ts">
import { ref, defineComponent, onBeforeMount } from "vue";
import { apiGet } from "@/api";
import { Toast } from "@/utils/notify";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";

export default defineComponent({
  name: "UserBankAccounts",
  components: { DualRingLoader },
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  // emits: ["bank-details"],
  setup(props) {
    const loading = ref(false);
    const data = ref(null);

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/user-account/${props.dt.id}/bank-accounts`
        );
        data.value = response.data.data.bankAccounts;

        // ctx.emit("bank-details", data.value);

        Toast.fire({
          icon: "success",
          title: "Bank Details Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      loading,
      data,
      RefreshData,
    };
  },
});
</script>
  
  <style scoped></style>
  